export const en = {
  locales: {
    en: 'English',
    pt: 'Portugês',
  },
  app: {
    title: 'My Pluggy',
    description:
      'Pluggy provides you easy access to your users financial data. We are a developer first fintech that makes Open Finance accessible to all innovators.',
  },
}
