import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'

import { UserProvider } from '@auth0/nextjs-auth0/client'
import '@pluggyai/ui/lib/light-theme.css'
import '@pluggyai/ui/lib/styles.css'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import Script from 'next/script'

import { TrackEventName } from '../lib/analytics/events'
import { page, track } from '../lib/analytics/utils'
import { urls } from '../lib/constants/urls'
import {
  INTERCOM_API_BASE,
  INTERCOM_WIDGET_ID,
  updateIntercom,
} from '../lib/intercom'
import '../styles/account.css'
import '../styles/globals.css'
import '../styles/my-pluggy.css'
import '../styles/oauth.css'
import '../styles/toast.css'
import { setLocale } from '../translations'

const HOTJAR_ID = process.env.NEXT_PUBLIC_HOTJAR_ID
// This is only needed if Hotjar ever updates the Tracking Code and needs to discontinue older ones.
// Knowing which version our site includes allows hotjar team to contact us and inform.
const CURRENT_HOTJAR_VERSION = 6

function App({ Component, pageProps }: AppProps): JSX.Element {
  const { locale, events } = useRouter()

  const languageLocale = locale?.split('-')[0] // Remove the country from the locale. From en-US to en
  setLocale(languageLocale || 'pt')

  useEffect(() => {
    const { referrer } = document
    if (!referrer.includes(urls.pluggyLandingPage())) {
      return
    }

    track(TrackEventName.LANDING_PAGE_VISIT)
  }, [])

  useEffect(() => {
    if (!HOTJAR_ID) {
      return
    }

    hotjar.initialize(Number.parseInt(HOTJAR_ID), CURRENT_HOTJAR_VERSION)
  }, [])

  useEffect(() => {
    const handleRouteChange = (): void => {
      // track page in Segment
      page()
      // refresh Intercom
      updateIntercom()
    }

    events.on('routeChangeComplete', handleRouteChange)
    // clean up
    return () => {
      events.off('routeChangeComplete', handleRouteChange)
    }
  }, [events])

  return (
    <UserProvider>
      {/* Intercom Web */}
      {/* Source: https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app */}
      {Boolean(INTERCOM_WIDGET_ID) && (
        <Script
          id="intercom"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
          (function() {
          var w = window
          var ic = w.Intercom
          if (typeof ic === 'function') {
            ic('reattach_activator')
            ic('update', w.intercomSettings)
          } else {
            var d = document
            var i = function() {
              i.c(arguments)
            }
            i.q = []
            i.c = function(args) {
              i.q.push(args)
            }
            w.Intercom = i
            var l = function() {
              var s = d.createElement('script')
              s.type = 'text/javascript'
              s.async = true
              s.src = 'https://widget.intercom.io/widget/${INTERCOM_WIDGET_ID}'
              var x = d.getElementsByTagName('script')[0]
              x.parentNode.insertBefore(s, x)
            }
            if (w.attachEvent) {
              w.attachEvent('onload', l)
            } else {
              w.addEventListener('load', l, false)
            }
          }

          // Call boot method
          Intercom('boot', {
            api_base: '${INTERCOM_API_BASE}',
            app_id: '${INTERCOM_WIDGET_ID}',
          })
        })()`,
          }}
        />
      )}

      <Component {...pageProps} />
    </UserProvider>
  )
}

export default App
