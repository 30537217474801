import { AnalyticsBrowser } from '@segment/analytics-next'

import { TrackEventName } from './events'

const analyticsApiKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY || ''

/**
 * Get analytics
 *
 * @returns - the analytics object that it's inside window
 */
function getAnalytics(): AnalyticsBrowser {
  const analytics = AnalyticsBrowser.load({ writeKey: analyticsApiKey })
  return analytics
}

/**
 * Send 'track' call to analytics.
 *
 * @param name - the name of the event to track
 * @param properties - extra pieces of information of the events to track (optional)
 * @param options - options to pass to Segment (optional)
 * @param callback - function to call when is done, only if we want to use it async (optional)
 */
export function track(
  name: TrackEventName,
  properties?: Record<string, unknown>,
  options?: Record<string, unknown>,
  callback?: () => void
): void {
  try {
    const analytics = getAnalytics()
    if (typeof analytics.track !== 'function') {
      callback?.()
      return
    }
    analytics.track(name, properties, options, callback)
  } catch (error) {
    // probably, analytics not enabled, or other issue sending the report
    // ignore this error - if not enabled it was already logged once
    callback?.()
  }
}

export function page(): void {
  try {
    const analytics = getAnalytics()
    if (typeof analytics.page !== 'function') {
      return
    }
    analytics.page()
  } catch (error) {
    console.error((error as Error).message)
  }
}

/**
 * Send 'identify' call to analytics.
 * Used to tie an user and their actions to a recognizable userId and traits
 *
 * @param userId - the id for the user to identify
 * @param traits - user-related data, such as name, email etc.
 * @param options - options to pass to Segment
 *
 */
export function identify(
  userId?: string,
  traits?: Record<string, unknown>,
  options?: Record<string, unknown>
): void {
  const analytics = getAnalytics()

  analytics.identify(userId, traits, options)
}
