export enum TrackEventName {
  BUTTON_CLICKED = 'Button Clicked',
  LINK_CLICKED = 'Link Clicked',
  ICON_CLICKED = 'Icon Clicked',
  ITEM_CREATED = 'Item Created',
  CONNECT_WIDGET_LOADED = 'Connect Widget Loaded',
  LOGIN_SUCCESS = 'Login Step Success',
  FORM_SUBMITTED = 'Form Submitted',
  PLUGGY_CONNECT_WIDGET_EVENT = 'Pluggy Connect Widget Event',
  MODAL_OPENED = 'Modal Opened',
  MODAL_CLOSED = 'Modal Closed',
  LANDING_PAGE_VISIT = 'Landing Page Visit',
}
