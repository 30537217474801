import rosetta from 'rosetta'

import { en } from './en'
import { pt } from './pt'

const translations: Record<string, unknown> = { en, pt }

const i18n = rosetta(translations)
let currentLocale = ''

export function setLocale(locale: string): void {
  if (!translations[locale]) {
    throw new Error(`Invalid locale ${locale}`)
  }
  currentLocale = locale
  i18n.locale(currentLocale)
}

export const t = i18n.t.bind(i18n) as typeof i18n.t
