import { getUnixTime } from 'date-fns'

export const INTERCOM_API_BASE = 'https://api-iam.intercom.io'
export const INTERCOM_WIDGET_ID = process.env.NEXT_PUBLIC_INTERCOM_WIDGET_ID

const nodeEnv = process.env.NODE_ENV

if (nodeEnv === 'production' && !INTERCOM_WIDGET_ID) {
  console.warn('Intercom not enabled due to missing WIDGET_ID')
}

/**
 * Load user data on Intercom messaging.
 * This should be called as soon as the user is logged in.
 *
 * @param id - user id
 * @param email - user email address
 * @param createdAt - user sign up date
 */
export function identifyUserInIntercom({
  id,
  email,
  createdAt,
}: {
  id: string
  email: string
  createdAt?: Date | string
}): void {
  if (!INTERCOM_WIDGET_ID) {
    // not configured
    return
  }

  const createdAtUnixTime = createdAt
    ? getUnixTime(new Date(createdAt))
    : undefined

  const intercomUserData: Intercom_.IntercomSettings = {
    api_base: INTERCOM_API_BASE,
    app_id: INTERCOM_WIDGET_ID,
    user_id: id,
    email,
    created_at: createdAtUnixTime,
  }

  window.Intercom('update', intercomUserData)
}

/**
 * Reload Intercom data by calling 'update' method on it.
 * According to the docs: this should be called whenever the view or URL changes in the app,
 * and will allow people to receive your most recent messages.
 */
export function updateIntercom(): void {
  if (!INTERCOM_WIDGET_ID) {
    // not configured
    return
  }

  Intercom('update')
}

/**
 * Helper to open Intercom chat, optionally with a preset message.
 *
 * @param {string} presetMessage
 */
export function showIntercom(presetMessage?: string): void {
  if (!INTERCOM_WIDGET_ID) {
    // not configured
    return
  }

  Intercom('showNewMessage', presetMessage)
}
