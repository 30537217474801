export const pt = {
  locales: {
    en: 'English',
    pt: 'Portugês',
  },
  app: {
    title: 'Meu Pluggy',
    description:
      'A Pluggy te dá acesso fácil aos dados financeiros dos seus usuários. Somos uma fintech com foco no desenvolvedor e que torna Open Finance acessível à todos os inovadores.',
  },
}
